// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/.pnpm/css-loader@6.11.0_webpack@5.93.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/.pnpm/css-loader@6.11.0_webpack@5.93.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.App {
  text-align: center; }

select:not([style]) {
  appearance: none;
  background-color: transparent; }

option:not([style]) {
  padding: 0;
  border: none;
  background: transparent;
  cursor: pointer; }

.MuiDataGrid-footerContainer {
  display: none !important; }

a {
  text-decoration: none;
  color: inherit; }

.input-ltr {
  direction: ltr !important; }
`, "",{"version":3,"sources":["webpack://./src/App.scss"],"names":[],"mappings":"AAGA;EACE,kBAAkB,EAAA;;AAGpB;EAGE,gBAAgB;EAChB,6BAA6B,EAAA;;AAG/B;EACE,UAAU;EACV,YAAY;EACZ,uBAAuB;EACvB,eAAe,EAAA;;AAGjB;EACE,wBAAwB,EAAA;;AAG1B;EACE,qBAAqB;EACrB,cAAc,EAAA;;AAGhB;EACE,yBAAyB,EAAA","sourcesContent":["@import \"variables.scss\";\n@import \"mixins.scss\";\n\n.App {\n  text-align: center;\n}\n\nselect:not([style]) {\n  -webkit-appearance: none;\n  -moz-appearance: none;\n  appearance: none;\n  background-color: transparent;\n}\n\noption:not([style]) {\n  padding: 0;\n  border: none;\n  background: transparent;\n  cursor: pointer;\n}\n\n.MuiDataGrid-footerContainer {\n  display: none !important;\n}\n\na {\n  text-decoration: none;\n  color: inherit;\n}\n\n.input-ltr {\n  direction: ltr !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
