import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import * as Inter from "../../interfaces/Interfaces";
import app from "../../utils/AxiosConfig";

const initialState: Inter.IData = {};

// a reducer for authentification with back-end
export const fetchUser = createAsyncThunk<
  Inter.IData,
  Inter.IAuth,
  { rejectValue: string }
>("auth/verifyUser", async (data: Inter.IAuth, { rejectWithValue }) => {
  try {
    const URL = `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_AUTH}`;
    const response = await axios.post<Inter.IData>(URL, data, {
      headers: {
        accept: "application/json",
        "Content-Type": "application/x-www-form-urlencoded",
      },
    });

    if (response.status === 200) {
      const URL = `${process.env.REACT_APP_GET_USER}`;
      const responseUser = await app.post<Inter.IGetUser>(URL);
      localStorage.setItem("role", responseUser.data.role);
      localStorage.setItem(
        "full_name",
        `${responseUser.data.name} ${responseUser.data.surname}`,
      );

      response.data = {
        ...response.data,
        isAuthenticated: "true",
        role: responseUser.data.role,
        username: responseUser.data.username,
      };
    }

    return response.data;
  } catch (error: unknown) {
    if (error instanceof axios.AxiosError) {
      alert("كلمة المرور او اسم المستخدم غير صحيحة");
      throw error;
    }
    alert("ERROR 404");
    throw error;
  }
});

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchUser.fulfilled, (_, action) => {
      sessionStorage.setItem("auth", "true");
      return action.payload;
    });
    builder.addCase(fetchUser.rejected, (state) => {
      sessionStorage.setItem("auth", "false");
      return state;
    });
  },
});

export default authSlice.reducer;
